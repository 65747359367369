import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MessageIcon from "@mui/icons-material/Message";

import { faqs } from "./text/faq";
import { instructions } from "./text/instructions";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Homepage() {
  return (
    <div
      style={{
        backgroundColor: "#bdbdbd",
        position: "relative",
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="relative">
          <Toolbar
            style={{
              backgroundColor: "#01579b",
            }}
          >
            <MessageIcon sx={{ mr: 2 }} />
            <Typography variant="h6" color="inherit" noWrap>
              Matrix-Server der Hochschule Augsburg
            </Typography>
          </Toolbar>
        </AppBar>
        <main>
          {/* Hero unit */}
          <Box
            sx={{
              bgcolor: "background.paper",
              pt: 8,
              pb: 6,
            }}
          >
            <Container maxWidth="lg">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Matrix-Server der Hochschule Augsburg
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                paragraph
              >
                Messaging-Dienst für Angehörige der Hochschule Augsburg
              </Typography>
            </Container>
          </Box>

          <Container sx={{ py: 4 }} maxWidth="md">
            <Typography gutterBottom variant="h4" component="h2" sx={{ py: 4 }}>
              Frequently Asked Questions
            </Typography>
            {faqs.map((card, index) => (
              <Grid item key={index} xs={12} sm={12} md={12} sx={{ py: 1 }}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="h2">
                      {card.question}
                    </Typography>
                    <Typography>{card.answer}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {/* End hero unit */}
            <Typography gutterBottom variant="h4" component="h2" sx={{ py: 4 }}>
              Schnellstart
            </Typography>
            {instructions.map((instruction, index) => (
              <Grid item key={index} xs={12} sm={12} md={12} sx={{ py: 1 }}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {instruction.title}
                    </Typography>
                    <Typography>{instruction.text}</Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    sx={{
                      p: 2,
                    }}
                    image={instruction.image}
                    alt="random"
                  />
                </Card>
              </Grid>
            ))}
            {/* </Grid> */}
          </Container>
        </main>
        {/* Footer */}
        {/* <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
          <Typography variant="h6" align="center" gutterBottom>
            Footer
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            component="p"
          >
            Something here to give the footer a purpose!
          </Typography>
          <Copyright />
        </Box> */}
        {/* End footer */}
      </ThemeProvider>
    </div>
  );
}
