export const instructions = [
  {
    title: "Erster Start",
    text: (
      <div>
        Dieses Fenster sollte erscheinen, wenn Sie Element zum ersten Mal
        öffnen. Wählen Sie hier den Button "Anmelden".
      </div>
    ),
    image: "start.png",
  },
  {
    title: "Anmeldung",
    text: (
      <div>
        Klicken Sie im nächsten Screen zuerst auf den "Bearbeiten"-Button bei
        "Heim-Server". Standardmäßig ist hier ein öffentlicher Matrix-Server
        eingestellt, dieser wird im nächsten Schritt geändert.
      </div>
    ),
    image: "log_in.png",
  },
  {
    title: "Änderung des Home-Servers",
    text: (
      <div>
        Wählen Sie zur Änderung des Home-Servers die Option "Anderer
        Heim-Server", geben Sie
        <nobr>
          <em> matrix.hs-augsburg.de </em>
        </nobr>
        in das Textfeld ein und klicken Sie auf "Fortfahren".
      </div>
    ),
    image: "homeserver.png",
  },
  {
    title: "Anmeldung",
    text: (
      <div>
        Jetzt sollte wieder die Anmeldeseite zu sehen sein. Geben Sie hier Ihre
        Zugangsdaten der Hochschule ein.
      </div>
    ),
    image: "log_in_2.png",
  },
  {
    title: "Startseite",
    text: (
      <div>
        Auf der Startseite können Sie mit den Buttons in der Mitte oder den
        "+"-Buttons an der Seite neue Direkt- oder Gruppennachrichten schreiben.
      </div>
    ),
    image: "welcome.png",
  },
  {
    title: "Neue Nachricht",
    text: (
      <div>
        Wenn Sie eine neue Direktnachricht senden, wird dieses Fenster
        angezeigt. In das Textfeld neben dem grünen "Los"-Button können Sie die
        Matrix-Adresse des Empfängers (im Format{" "}
        <nobr> @&lt;RZ-Account&gt;:matrix.hs-augsburg.de</nobr>) eingeben. Mit
        einem Klick auf "Los" öffnet sich dann der Chat.
      </div>
    ),
    image: "message.png",
  },
];
