import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

export const faqs = [
  {
    question: "Was wird benötigt, um den Matrix-Server zu nutzen?",
    answer: (
      <div>
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
        >
          <ListItem>RZ-Account der Hochschule Augsburg</ListItem>
          <ListItem>Matrix-Client auf Desktop- oder Mobilgerät</ListItem>
        </List>
        Wir empfehlen den kostenlosen Client{" "}
        <a href="https://element.io/download">Element</a>.
      </div>
    ),
  },
  {
    question: "Wie kann ich andere Personen erreichen?",
    answer: (
      <div>
        Der Aufbau von Matrix-Adressen ist an der Hochschule Augsburg wie folgt:
        <nobr> @&lt;RZ-Account&gt;:matrix.hs-augsburg.de</nobr>. Beispiel
        (RZ-Account: "test"):
        <nobr> @test:matrix.hs-augsburg.de</nobr> Beachten Sie jedoch: der Name
        des RZ-Accounts ist meistens NICHT der Anfang der E-Mail-Adresse. <br />
        Um eine Nachricht zu senden, klicken Sie auf das "+"-Symbol und wählen
        Sie "Neue Direktnachricht". Dort können Sie die gewünschte
        Matrix-Adresse eingeben.
      </div>
    ),
  },
  {
    question: "Kann ich Voice- und Videochats nutzen?",
    answer: (
      <div>
        Ja, Sie können ebenfalls Anrufe und Videochats in vielen Matrix-Clients
        starten. Hier gibt es jedoch zwei verschiedene Typen:
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
        >
          <ListItem>
            Anrufe mit einer anderen Person: Dies wird vom Matrix-Server direkt
            unterstützt, diese laufen über den Matrix-Server der Hochschule.
          </ListItem>
          <ListItem>
            Anrufe mit mehreren Personen: Dies wird vom Matrix-Server aktuell
            nicht direkt unterstützt. Stattdessen werden von den Matrix-Clients
            meist externe Dienste wie <a href="https://jitsi.org">Jitsi</a>{" "}
            genutzt. Falls Sie dies nicht möchten, können Sie alternativ auch
            einen Link zu einer Videokonferenz in den Chat posten.
          </ListItem>
        </List>
      </div>
    ),
  },

  {
    question:
      'Ich erhalte die Meldung "Schlüsselsicherung einrichten". Was bedeutet das?',
    answer: (
      <div>
        Matrix unterstützt Ende-zu-Ende-Verschlüsselung, wodurch die Nachrichten
        auf den Endgeräten selbst verschlüsselt werden und der Server die
        Nachrichten nicht mitlesen kann. Daher wird der benötigte Schlüssel
        nicht auf den Server übertragen. Bei Verlust des Schlüssels kann also
        der Nachrichtenverlauf von uns NICHT mehr wiederhergestellt werden.{" "}
        <br />
        Damit Ihre Nachrichten nicht verloren gehen, sollten Sie den Schlüssel
        dafür sichern. Wir empfehlen Ihnen, den Schlüssel auch auf einem anderen
        Gerät zu speichern, damit dieser auch bei Verlust des Gerätes
        wiederhergestellt werden kann.
      </div>
    ),
  },
  {
    question: "Wie kann ich den Support erreichen?",
    answer: (
      <div>
        Sie erreichen den Support per E-Mail unter{" "}
        <a href="mailto:matrix@hs-augsburg.de">matrix@hs-augsburg.de</a>.
      </div>
    ),
  },
  //   {
  //     question:
  //       'Ich erhalte die Fehlermeldung "Gerät konnte nicht verifiziert werden", nachdem ich ein anderes Gerät eingerichtet habe. Was bedeutet das?',
  //     answer: (
  //       <div>
  //         Sobald Sie sich an mehr als einem Gerät anmelden, müssen Sie neue Geräte
  //         verifizieren. Dies ist eine zusätzliche Sicherheitsmaßnahme, damit b
  //       </div>
  //     ),
  //   },
];
